(() => {
  const mobileMenuOpen = document.querySelector(
    '.def-header__navigation-mobile-open'
  );
  const mobileMenuClose = document.querySelector(
    '.def-header__navigation-mobile-close'
  );
  const mobileMenu = document.querySelector('.def-header__navigation');

  mobileMenuOpen.addEventListener('click', evt => {
    evt.preventDefault();
    openMenu();
  });

  mobileMenuClose.addEventListener('click', evt => {
    evt.preventDefault();
    closeMenu();
  });

  function openMenu() {
    mobileMenu.classList.add('def-header__navigation--open');
  }

  function closeMenu() {
    mobileMenu.classList.remove('def-header__navigation--open');
  }
})();
